body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.completed-task {
  text-decoration: line-through;
  color: #db3545;
}

.w-100 {
  width: 100%;
}

.drag-anchor {
  padding-right: 20px !important;
}

.list-group-item {
  padding-left: 0;
}

.input-group + .input-group{
  padding-top: 5px;
}
.input-select {
  border: 0px;
  padding: 0px;
}